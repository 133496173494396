import { createRouter } from 'vue-router'


const router = createRouter({
    history: {
        base: "/"
    },
    routes: [
        // Vos routes ici
    ]
})

export default router