import homeapi from "./homeapi"

const webrtc = {
    connections: {},
    myStream: null,
    onStream: null,
    ignoreOffer: false,

    init() {
        homeapi.onSignalerMessage = async (data) => {
            try {
                let fromDevice = homeapi.home.devices.find((d) => d.id == data.fromId);
                let pc = this.connections[fromDevice.id].peer;

                if (data.description) {
                    data.description.type = data.description.type.toLowerCase();
                    const offerCollision =
                        data.description.type === "offer" &&
                        (fromDevice.makingOffer || pc.signalingState !== "stable");

                    this.ignoreOffer = !fromDevice.polite && offerCollision;
                    if (this.ignoreOffer) {
                        return;
                    }

                    await pc.setRemoteDescription(data.description);
                    if (data.description.type === "offer") {
                        await pc.setLocalDescription();
                        homeapi.sendSignalement({
                            description: pc.localDescription,
                            toId: fromDevice.id,
                        });
                    }
                } else if (data.candidate) {
                    try {
                        await pc.addIceCandidate(data.candidate);
                    } catch (err) {
                        if (!this.ignoreOffer) {
                            throw err;
                        }
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };
    },

    updatePeers: function () {
        if (homeapi.myDeviceId == -1) return;
        let home = homeapi.home;
        let filteredDevices = [];
        for (let i = 0; i < home.devices.length; i++) {
            if (home.devices[i].id != homeapi.myDeviceId) {
                console.log(
                    "Device " +
                    i +
                    " => Receving:" +
                    home.devices[i].receiving +
                    " Diffusing:" +
                    home.devices[i].diffusing +
                    " MyDevice =>  Receving:" +
                    homeapi.device.receiving +
                    " Diffusing:" +
                    homeapi.device.diffusing
                );

                if (
                    (home.devices[i].receiving && homeapi.device.diffusing) ||
                    (home.devices[i].diffusing && homeapi.device.receiving)
                ) {
                    filteredDevices.push(home.devices[i]);
                }
            }
        }

        for (let i = 0; i < filteredDevices.length; i++) {
            if (this.connections[filteredDevices[i].id] == undefined) {
                this.connections[filteredDevices[i].id] = this.createDeviceConnection(
                    filteredDevices[i]
                );
            }
        }

        let toRemove = [];
        for (let deviceId in this.connections) {
            let isInDevices = false;
            for (let i = 0; i < filteredDevices.length; i++) {
                if (filteredDevices[i].id == deviceId) isInDevices = true;
            }
            if (!isInDevices) {
                toRemove.push(deviceId);
            }
        }
        for (let i = 0; i < toRemove.length; i++) {
            this.destroyDeviceConnection(toRemove[i]);
        }
        toRemove = [];

        for (let i = 0; i < filteredDevices.length; i++) {
            let d = filteredDevices[i];
            if (homeapi.device.diffusing && d.receiving) {
                this.addLocalTracks(d.id);
            } else {
                this.removeLocalTracks(d.id);
            }
        }

        console.log(
            "Devices connected => " + Object.keys(this.connections).length
        );
    },

    addLocalTracks: function (deviceId) {
        if (this.myStream == null) return;

        for (const track of this.myStream.getTracks()) {
            if (!this.connections[deviceId].hasTrack(track)) {
                console.log("Add Track ", track, "On Device " + deviceId);
                this.connections[deviceId].addTrack(track, this.myStream);
            }
        }
    },


    removeLocalTracks: function (deviceId) {
        this.connections[deviceId].removeTracks();
    },

    createDeviceConnection: function (device) {
        console.log("Create Device Connection " + device.id);
        const pc = new RTCPeerConnection({
            iceServers: [{ urls: "stun:stun.l.google.com:19302" },
            {
                urls: "turn:ec2-54-202-227-44.us-west-2.compute.amazonaws.com:3478",
                username: "webrtc_terminus",
                credential: "p4YArEy6JVwRjr",
            },
            ],
        });
        pc.onconnectionstatechange = () => {
            console.log(
                "État de connexion du pair " + device.id + ":" + pc.connectionState
            );
        };

        pc.ontrack = ({ track, streams }) => {
            console.log("OnTrack " + track.id + " " + track.kind + " " + device.id);
            this.onStream(device.id, streams[0]);
        };

        pc.onnegotiationneeded = async () => {
            try {
                device.makingOffer = true;
                await pc.setLocalDescription();
                homeapi.sendSignalement({
                    type: "offer",
                    description: pc.localDescription,
                    toId: device.id,
                });
            } catch (err) {
                console.error(err);
            } finally {
                device.makingOffer = false;
            }
        };
        pc.onicecandidate = ({ candidate }) =>
            homeapi.sendSignalement({
                type: "candidate",
                candidate: candidate,
                toId: device.id,
            });

        let deviceConnection = {
            peer: pc,
            senders: [],
            trackIds: [],
            addTrack(track, stream) {
                if (this.hasTrack(track.id)) return;
                this.trackIds.push(track.id);
                this.senders.push(this.peer.addTrack(track, stream));
            },
            removeTracks() {
                for (let i = 0; i < this.senders.length; i++) {
                    this.peer.removeTrack(this.senders[i]);
                    this.senders.splice(i, 1);
                    i--;
                }
                this.trackIds = [];
            },
            hasTrack(track) {
                return this.trackIds.includes(track.id);
            },
        };

        return deviceConnection;
    },
    destroyDeviceConnection: function (deviceId) {
        console.log("Destroy Device Connection " + deviceId);
        if (this.connections[deviceId] != undefined) {
            for (let i = 0; i < this.connections[deviceId].senders.length; i++) {
                console.log(
                    "Destroy Sender ",
                    this.connections[deviceId].senders[i]
                );
                this.connections[deviceId].peer.removeTrack(
                    this.connections[deviceId].senders[i]
                );
            }

            this.connections[deviceId].peer.close();
            this.connections[deviceId].peer = null;
            delete this.connections[deviceId];
        }
    }

}

export default webrtc;