<template>
  <v-app>
    <v-container>
      <h1 style="text-align: center" class="my-10">{{ name }}</h1>
      <v-row justify="center" align="center">
        <v-col v-for="(s, i) in deviceStreams" :key="'remotestream-' + i + '-' + s.device.id"
          :id="'remotestream-' + i + '-' + s.device.id" cols="auto">
          <remote-camera :stream="s.stream" v-model:value="s.device"></remote-camera>
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-col v-for="(s, i) in streams" :key="'stream' + i" cols="auto">
          <v-card>
            <video class="videoview" :ref="'video-' + s.id" autoplay controls :muted="true" :srcObject.prop="s" />
            <v-card-title>You</v-card-title>

            <v-card-actions>
              <v-btn color="secondary" @click="toggleDiffuse">
                Arreter la diffusion</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-btn class="mt-4" color="primary" @click="toggleDiffuse" v-if="!diffusing">
          Diffuser</v-btn>
        <v-btn href="/app.apk">Télécharger l'application</v-btn>
      </v-row>
      <!-- Votre contenu ici -->
    </v-container>
  </v-app>
</template>
<style lang="scss" scoped>
.videoview {
  width: 400px;
  height: 400px;
  background-color: black;
}
</style>

<script>
import RemoteCamera from "./components/RemoteCamera.vue";
import homeapi from "./homeapi.js";
import webrtc from "./webrtc.js";
export default {
  name: "App",
  components: { RemoteCamera },
  data() {
    return {
      name: "Youcouland",
      streams: [],
      myStream: null,
      diffusing: false,
      deviceStreams: {},
      devices: [],
    };
  },

  mounted() {
    webrtc.init();

    homeapi.connectTo(this.name, () => {
      webrtc.updatePeers();
    });

    homeapi.onHomeUpdated = () => {
      webrtc.updatePeers();
      this.devices = homeapi.home.devices;
    };

    webrtc.onStream = (deviceId, stream) => {
      this.deviceStreams[deviceId] = {
        stream: stream,
        device: homeapi.home.devices.find((d) => d.id == deviceId),
      };
    };
  },

  methods: {
    changeDiffusionState(device, newDiffusing) {
      device.diffusing = newDiffusing;
      homeapi.updateDevice(device);
    },
    toggleDiffuse() {
      this.diffusing = !this.diffusing;

      if (this.diffusing) {
        const constraints = { video: true, audio: true };
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          this.streams.push(stream);
          this.myStream = stream;
        });
      } else {
        this.streams.splice(this.streams.indexOf(this.myStream), 1);
        this.myStream = null;
      }
      this.changeDiffusionState(homeapi.device, this.diffusing);
    },
  },
};
</script>