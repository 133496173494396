<template>
  <v-card v-if="device">
    <video
      class="videoview"
      :ref="'video-' + device.id - '-' + stream.id"
      autoplay
      controls
      :srcObject.prop="stream"
    />
    <v-card-title>{{ device.name }} ({{ device.ip }})</v-card-title>
    <v-card-subtitle
      ><v-icon>mdi-battery</v-icon>{{ device.battery }}%</v-card-subtitle
    >

    <v-container>
      <v-text-field
        label="Message"
        placeholder="Dire quelquechose sur l'appareil..."
        v-model="message"
        hideDetails=""
        class="mb-2"
        append-inner-icon="mdi-send"
        @keyup.enter="submitTTS()"
        @click:appendInner="submitTTS()"
      />
      <v-slider max="100" min="0" v-model="volume"></v-slider>
      <v-row dense>
        <v-col cols="6"
          ><v-select
            hide-details=""
            label="Camera"
            v-model="device.cameraIndex"
            :items="device.cameraIds"
        /></v-col>
        <v-col cols="6"
          ><v-select
            hide-details=""
            label="Voix"
            v-model="ttsVoice"
            :items="device.voices"
        /></v-col>
        <v-col cols="3"
          ><v-text-field hide-details="" label="Hauteur" v-model.number="pitch"
        /></v-col>
        <v-col cols="3"
          ><v-text-field
            hide-details=""
            label="Vitesse"
            v-model.number="speechRate"
        /></v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-btn
        @click="
          device.diffusing = !device.diffusing;
          updateDevice();
        "
        color="secondary"
        icon
      >
        <v-icon>{{ device.diffusing ? "mdi-video" : "mdi-video-off" }}</v-icon>
      </v-btn>
      <v-btn
        @click="
          device.useFlashlight = !device.useFlashlight;
          updateDevice();
        "
        color="secondary"
        icon
      >
        <v-icon>{{
          device.useFlashlight ? "mdi-flashlight" : "mdi-flashlight-off"
        }}</v-icon></v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<style lang="scss" scoped>
.videoview {
  width: 400px;
  height: 400px;
  background-color: black;
}
</style>
<script>
import homeapi from "../homeapi.js";

export default {
  props: ["stream", "value"],
  data() {
    return {
      pitch: 1,
      speechRate: 1,
      ttsVoice: "",
      message: "",
      volume: 50,
      cameraId: "",
      devices: homeapi.home.devices,
    };
  },
  computed: {
    device: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.volume = this.value.volume * 100;
  },
  methods: {
    updateDevice() {
      console.log("update device");
      homeapi.updateDevice(this.device);
    },
    updateVolume() {
      console.log("volume changed => " + this.volume / 100);
      this.device.volume = this.volume / 100;
      this.updateDevice();
      homeapi.sendMessage({
        socket_id: this.value.socket_id,
        type: "CHANGE_VOLUME",
        data: {
          percentage: this.volume / 100,
        },
      });
    },
    submitTTS() {
      console.log("submitTTS");
      homeapi.sendMessage({
        socket_id: this.device.socket_id,
        type: "TEXT_TO_SPEECH",
        data: {
          message: this.message,
          voice: this.ttsVoice,
          pitch: this.pitch,
          speechRate: this.speechRate,
        },
      });
    },
  },
  watch: {
    volume: function () {
      this.updateVolume();
    },
    "device.cameraIndex": function () {
      this.updateDevice();
    },
  },
};
</script>