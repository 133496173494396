
import socketio from 'socket.io-client';

const homeapi = {
    socket: null,
    myDeviceId: null,
    device: null,
    home: null,

    connectTo(homeName, callback) {
        this.device = {
            receiving: true,
            diffusing: false,
            name: navigator.userAgent
        }
        this.socket = socketio("https://ca.router.casual-vr.com", {
            secure: true
        });
        this.socket.emit(
            "connect-home",
            JSON.stringify({
                homeName: homeName,
                device: this.device,
            }),
            (r) => {
                console.log("connected to ", r.home);
                this.home = r.home;
                this.myDeviceId = r.device_id;
                if (callback != null) callback();


            }
        );

        this.socket.on("webrtc-messaging", (args) => {
            console.log("webrtc-messaging", args);
            this.onSignalerMessage(args);
        });
        this.socket.on("home-updated", (args, b) => {
            this.home = b;
            console.log("on home updated", b);
            this.onHomeUpdated(b);
        });
    },

    updateMyDevice(callback) {
        this.socket.emit("update-device", JSON.stringify(this.device), () => {
            console.log("on device updated");
            callback();
        });
    },
    updateDevice(device, callback) {
        this.socket.emit("update-device", JSON.stringify(device), () => {
            console.log("on device updated");
            if (callback != null) callback();
        });
    },
    sendMessage(args) {
        this.socket.emit("direct-message", JSON.stringify(args), () => {
            console.log("on device updated");
        });
    },


    sendSignalement(data) {

        let toDevice = this.home.devices.find((d) => d.id == data.toId);
        data.fromId = this.myDeviceId;
        data.socket_id = toDevice.socket_id;
        this.socket.emit("webrtc-messaging", data);
    },

    onHomeUpdated: null,
    onSignalerMessage: null
}

export default homeapi;